export function formatNumber(
    value: string | number | undefined,
    decimals: number = 2
): string {
    if (isNaN(Number(value)) || value === undefined) {
        return formatNumber(0, 2);
    }

    const numValue = typeof value === "number" ? value : parseFloat(value!);
    const withDecimals = numValue.toFixed(decimals);
    const [whole, decimal] = withDecimals.split(".");
    const wholeWithSeparator = parseInt(whole, 10).toLocaleString("en-US");
    let ret;
    if (decimal === undefined) ret = wholeWithSeparator;
    else ret = `${wholeWithSeparator}.${decimal}`;
    if (parseFloat(ret) === 0) ret = "0.0";
    return ret;
}