import React from "react";
import {
  Box,
  Container
} from "@mui/material";
import TitleSection from "../../components/TitleSection";
import Items from "./items";
import { datas_faq } from "../../utils/faq";

const SectionFAQ = () => {
  return (
    <Box id="SectionFAQ" className="container-section">
      <Container className="container-in margin" sx={{ zIndex: 1, pt: 10, pb: 5 }}>
        <TitleSection Title="FAQ" sx={{ mb: 2 }} />
        <Box className="FAQ" component="div" sx={{ borderColor: 'secondary.main' }}>
          <Items Datas={datas_faq} />
        </Box>
      </Container>
    </Box>
  );
};

export default SectionFAQ;
