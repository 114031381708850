import createPalette from '@mui/material/styles/createPalette';
import createTheme from '@mui/material/styles/createTheme';
import createTypography from '@mui/material/styles/createTypography';

const theme = createTheme();
export const typography = createTypography(createPalette({}), {
  fontFamily: ['Gotham'].join(','),
  fontSize: 16,
  h1: {
    fontSize: '3rem',
    fontWeight: 700,
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5rem'
    },
    textTransform: 'capitalize',
    color: '#fff'
  },
  h2: {
    fontSize: '2.5rem',
    lineHeight: 'inherit',
    fontWeight: '700',
    [theme.breakpoints.down('md')]: {
      fontSize: '2,2rem'
    },
    textTransform: 'capitalize',
    color: '#fff'
  },
  h3: {
    fontSize: '2.5rem',
    lineHeight: 'inherit',
    fontWeight: '300',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem'
    },
    color: '#fff'
  },
  h4: {
    fontSize: '1.25rem',
    fontWeight: '700',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.15rem'
    },
    color: '#fff'
  },
  h5: {
    fontSize: '1rem',
    fontWeight: '700',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    },
    color: '#fff'
  },
  button: {
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 'normal'
  },
  subtitle1: {
    fontSize: '2rem',
    lineHeight: 'inherit',
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem'
    },
    color: '#fff'
  },
  subtitle2: {
    fontSize: '2rem',
    lineHeight: 'inherit',
    fontWeight: 300,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem'
    }
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '1.625rem',
    fontWeight: 'normal'
  },
  body2: {
    fontSize: '1rem',
    lineHeight: 'inherit',
    fontWeight: 'normal'
  },
  caption: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: '700',
    color: '#fff'
  }
});