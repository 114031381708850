import React, { useEffect, useRef } from "react";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { useCountUp } from "react-countup";
import { Waypoint } from "react-waypoint";

interface Props {
  Number: number;
  Format: Array<"$" | "%" | "">;
  SubText: string;
  Duration?: number;
  sx?: SxProps<Theme>;
}

const NumberCountUp = ({ Number, Format, SubText, Duration = 2, sx = [] }: Props) => {
  const countUpRef = useRef<HTMLSpanElement | null>(null);
  const { start, reset, pauseResume, update } = useCountUp({
    ref: countUpRef,
    start: Math.round(Number / 10),
    end: Number,
    delay: 1000,
    duration: Duration,
    separator: ",",
    decimals: Format.includes("%") ? 2 : 0,
    decimal: ".",
    prefix: Format.includes("$") ? Format[0] : "",
    suffix: Format.includes("%") ? Format[0] : ""
  });

  useEffect(() => {
    update(Number);
  }, [Number, update]);

  const Launch = () => {
    reset();
    start();
    pauseResume();
  };

  return (
      <Box sx={sx}>
        <Waypoint onEnter={Launch}>
          <Box>
            <Typography variant="h1">
              <span ref={countUpRef} />
            </Typography>
            <Typography variant="h4">{SubText}</Typography>
          </Box>
        </Waypoint>
      </Box>
  );
};

export default NumberCountUp;
