import React from "react";
import { Box, Container, Grid, SxProps, Theme, Typography } from "@mui/material";
import backgroundSection from "./../../assets/images/background-association.jpg";
import { AnimationOnScroll } from "react-animation-on-scroll";
import TitleSection from "../../components/TitleSection";
import LogoLink from "../../components/LogoLink";
import TitleIcon from "../../components/TitleIcon";
import {
  BAILSEC_URL,
  BASE_URL,
  EIGEN_URL,
  EIGENDA_URL,
  ETHEREUM_URL,
  ETHERFI_URL,
  KELP_URL,
  MEV_URL,
  OXORIO_URL,
  POLYGON_URL,
  RENZO_URL,
  SALUS_URL,
  SHERLOCK_URL,
  XBTO_URL,
  ZIRCUIT_URL,
  SYMBIOTIC_URL,
  MELLOW_URL,
  BEDROCK_URL,
} from "../../constants/urls";

const sxSectionBackground: SxProps<Theme> = (theme: Theme) => ({
  background: `transparent url(${backgroundSection}) center center no-repeat`,
  [theme.breakpoints.down("sm")]: {
    background: `transparent url() center center no-repeat`,
  },
  backgroundAttachment: "fixed",
  backgroundSize: "cover",
});

const SectionAssociation = () => {
  return (
    <Box id="SectionAssociation" className="container-section margin" sx={sxSectionBackground}>
      <Container className="container-in margin" sx={{ py: 10 }}>
        <TitleSection Title="Associated with" sx={{ mb: 3 }} />
        <TitleIcon
          Title="Powered by"
          Icon={"assets/images/icon-powered.svg"}
          IconHeight="3rem"
          Variant="h4"
          sx={{ textAlign: "center", mb: 2 }}
        />
        <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut" offset={150}>
          <Box className="partners-grid">
            <Grid container spacing={1} justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={6} lg={4} order={{ xs: 2, lg: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <LogoLink
                      Title={"Symbiotic"}
                      Url={SYMBIOTIC_URL}
                      Img={"assets/images/partners/symbiotic.svg"}
                      ImgHover={"assets/images/partners/symbiotic-hover.svg"}
                      sx={{ maxWidth: "320px" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LogoLink
                      Title={"MELLOW"}
                      Url={MELLOW_URL}
                      Img={"assets/images/partners/mellow.svg"}
                      ImgHover={"assets/images/partners/mellow-hover.svg"}
                      sx={{ maxWidth: "170px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={4} order={{ xs: 1, lg: 2 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <LogoLink
                      Title={"Eigen Layer"}
                      Url={EIGEN_URL}
                      Img={"assets/images/partners/eigen.svg"}
                      ImgHover={"assets/images/partners/eigen-hover.svg"}
                      sx={{ maxWidth: "321px" }}
                    />
                    <LogoLink
                      Title={"Eigen DA"}
                      Url={EIGENDA_URL}
                      Img={"assets/images/partners/eigenda.svg"}
                      ImgHover={"assets/images/partners/eigenda-hover.svg"}
                      sx={{ maxWidth: "90px", mt: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LogoLink
                      Title={"Zircuit"}
                      Url={ZIRCUIT_URL}
                      Img={"assets/images/partners/zircuit.svg"}
                      ImgHover={"assets/images/partners/zircuit-hover.svg"}
                      sx={{ maxWidth: "166px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} order={{ xs: 3 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <LogoLink
                      Title={"RENZO"}
                      Url={RENZO_URL}
                      Img={"assets/images/partners/renzo.svg"}
                      ImgHover={"assets/images/partners/renzo-hover.svg"}
                      sx={{ maxWidth: "141px" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LogoLink
                      Title={"Ether.fi"}
                      Url={ETHERFI_URL}
                      Img={"assets/images/partners/etherfi.svg"}
                      ImgHover={"assets/images/partners/etherfi-hover.svg"}
                      sx={{ maxWidth: "170px" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LogoLink
                      Title={"Bedrock"}
                      Url={BEDROCK_URL}
                      Img={"assets/images/partners/bedrock.svg"}
                      ImgHover={"assets/images/partners/bedrock-hover.svg"}
                      sx={{ maxWidth: "170px" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LogoLink
                      Title={"Kelp"}
                      Url={KELP_URL}
                      Img={"assets/images/partners/kelp.svg"}
                      ImgHover={"assets/images/partners/kelp-hover.svg"}
                      sx={{ maxWidth: "136px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent="center" sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6} lg={4}>
                <TitleIcon
                  Title="Chains"
                  Icon={"assets/images/icon-chain.svg"}
                  IconHeight="3rem"
                  Variant="h4"
                  sx={{ textAlign: "center", mt: 3, mb: 1 }}
                />
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <LogoLink
                      Title={"Ethereum"}
                      Url={ETHEREUM_URL}
                      Img={"assets/images/partners/ethereum.svg"}
                      ImgHover={"assets/images/partners/ethereum-hover.svg"}
                      sx={{ maxWidth: "242px" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LogoLink
                      Title={"Polygon"}
                      Url={POLYGON_URL}
                      Img={"assets/images/partners/polygon.svg"}
                      ImgHover={"assets/images/partners/polygon-hover.svg"}
                      sx={{ maxWidth: "194px" }}
                    />
                    <Typography component="div" variant="body2" textAlign="center" color="secondary.main">
                      Coming soon
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LogoLink
                      Title={"Base"}
                      Url={BASE_URL}
                      Img={"assets/images/partners/base.svg"}
                      ImgHover={"assets/images/partners/base-hover.svg"}
                      sx={{ maxWidth: "100px" }}
                    />
                    <Typography component="div" variant="body2" textAlign="center" color="secondary.main">
                      Coming soon
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TitleIcon
                  Title="Auditors"
                  Icon={"assets/images/icon-auditor.svg"}
                  IconHeight="3rem"
                  Variant="h4"
                  sx={{ textAlign: "center", mt: 3, mb: 1 }}
                />
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item xs={12}>
                    <LogoLink
                      Title={"Sherlock"}
                      Url={SHERLOCK_URL}
                      Img={"assets/images/partners/sherlock.svg"}
                      ImgHover={"assets/images/partners/sherlock-hover.svg"}
                      sx={{ maxWidth: "244px" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LogoLink
                      Title={"Bail security"}
                      Url={BAILSEC_URL}
                      Img={"assets/images/partners/bail-security.svg"}
                      ImgHover={"assets/images/partners/bail-security-hover.svg"}
                      sx={{ maxWidth: "130px" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LogoLink
                      Title={"Salus"}
                      Url={SALUS_URL}
                      Img={"assets/images/partners/salus.svg"}
                      ImgHover={"assets/images/partners/salus-hover.svg"}
                      sx={{ maxWidth: "135px" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LogoLink
                      Title={"Oxorio"}
                      Url={OXORIO_URL}
                      Img={"assets/images/partners/oxorio.svg"}
                      ImgHover={"assets/images/partners/oxorio-hover.svg"}
                      sx={{ maxWidth: "145px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TitleIcon
                  Title="Liquidity Providers"
                  Icon={"assets/images/icon-liquidity.svg"}
                  IconHeight="3rem"
                  Variant="h4"
                  sx={{ textAlign: "center", mt: 3, mb: 1 }}
                />
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <LogoLink
                      Title={"MEV Capital"}
                      Url={MEV_URL}
                      Img={"assets/images/partners/mev-capital.svg"}
                      ImgHover={"assets/images/partners/mev-capital-hover.svg"}
                      sx={{ maxWidth: "108px" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LogoLink
                      Title={"XBTO"}
                      Url={XBTO_URL}
                      Img={"assets/images/partners/xbto.svg"}
                      ImgHover={"assets/images/partners/xbto-hover.svg"}
                      sx={{ maxWidth: "108px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </AnimationOnScroll>
      </Container>
    </Box>
  );
};

export default SectionAssociation;
