import React, { useState } from "react";
import {
  Box,
  CardMedia,
  Container,
  SxProps,
  Theme
} from "@mui/material";
import { Waypoint } from "react-waypoint";
import VideoBackground from "./../../assets/videos/video-particles-default.mp4";
import Adaptive from "./Adaptative";
import Resilient from "./Resilient";
import LRTFocused from "./LRTFocused";
import TitleSection from "../../components/TitleSection";

const sxVideoBackground: SxProps<Theme> = (theme: Theme) => ({
  width: "100%",
  height: "100vh",
  objectFit: "contain",
  position: "fixed",
  top: 0,
  [theme.breakpoints.down("md")]: {
    objectFit: "cover",
  },
});

const sxContainerContent: SxProps<Theme> = (theme: Theme) => ({
  /*overflow: "hidden"*/
});

const SectionWhy = () => {
  const [BackgroundHide, setBackgroundHide] = useState(false);

  return (
    <Box id="SectionWhy" className="container-section">
      <CardMedia
        component="video"
        sx={sxVideoBackground}
        src={VideoBackground}
        autoPlay={true}
        controls={false}
        loop={true}
        muted={true}
        playsInline={true}
        className={BackgroundHide ? "" : "hide"}
      />
      <Container
        className="container-in margin"
        sx={{ zIndex: 100, py: 10 }}
      >
        <TitleSection Title="Why Amphor ?" sx={{ mb: 2 }} />
        <Waypoint
          onEnter={() => setBackgroundHide(true)}
          onLeave={() => setBackgroundHide(false)}
        >
          <Box sx={sxContainerContent}>
            <Adaptive sx={{ py: 2, minHeight: '75vh' }} />
            <Resilient sx={{ py: 2, minHeight: '75vh' }} />
            <LRTFocused sx={{ py: 2, minHeight: '75vh' }} />
          </Box>
        </Waypoint>
      </Container>
    </Box>
  );
};

export default SectionWhy;
