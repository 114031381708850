import React from "react";
import { Button, Typography } from "@mui/material";
import { AMPHORAPP_URL } from "../../constants/urls";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface OpenAppProps {
  text?: string;
}

const OpenApp: React.FC<OpenAppProps> = ({ text = "Launch APP" }) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      endIcon={<ArrowForwardIcon color="primary" />}
      href={AMPHORAPP_URL}
      target="_blank"
    >
      <Typography fontWeight="700" variant="button" align="center">
        {text}
      </Typography>
    </Button>
  );
};

export default OpenApp;
