import { useQuery } from "@tanstack/react-query";
import { IPerformances } from "../types/performances";


const fetchVaultPerf = async (
    vaultSelected: string,
    networkId?: number,
): Promise<IPerformances> => {
    const response = await fetch(
        `https://app.amphor.io/api/vault-performances?vault=${vaultSelected}&networkId=${networkId}`,
    );
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    return response.json();
};

export const useVaultPerf = (
    vaultSelected: string,
    networkId?: number,
) => {
    return useQuery(
        {
            queryKey: ["vault-performances", vaultSelected,
                networkId], queryFn:
                () => fetchVaultPerf(vaultSelected, networkId),
            enabled: vaultSelected !== undefined && networkId !== undefined,
        },
    );
};
