import React from "react";
import {
  Box,
  CardMedia,
  Stack,
  SxProps,
  Theme,
  Typography
} from "@mui/material";
import {AnimationOnScroll} from "react-animation-on-scroll";

interface Props {
  Title: JSX.Element | string;
  Icon: JSX.Element | string;
  IconHeight?: string;
  Variant?: any;
  sx?: SxProps<Theme>;
}

const TitleIcon = ({
  Title,
  Icon,
  Variant = "h4",
  IconHeight = "1rem",
  sx = []
}: Props) => {
  return (
    <AnimationOnScroll
      animateIn="animate__FadeIn"
      animateOut="animate__FadeOut"
      offset={150}
    >
      <Box sx={sx}>
        <Box sx={{ display: "inline-block" }}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Box>
              {typeof Icon === "string" ? (
                <CardMedia
                  component="img"
                  src={require("./../../" + Icon)}
                  sx={{ height: IconHeight }}
                />
              ) : (
                Icon
              )}
            </Box>
            <Typography component="div" variant={Variant} align="center" sx={{ mt: 0.25 }}>
              {Title}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </AnimationOnScroll>
  );
};

export default TitleIcon;
