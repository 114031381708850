import React from "react";
import { Box, CardMedia, CircularProgress, Container, Stack, SxProps, Theme, Typography } from "@mui/material";
import OpenAPP from "../../components/OpenApp";
import ETHRestaking from "../../components/ETHRestaking";
import BTCRestaking from "../../components/BTCRestaking";
import SocialLinks from "../../components/SocialLinks";

import VideoStart from "./../../assets/videos/video-start.mp4";
import StartsCountdown from "../../components/StartsCountdown";
import { useTVL } from "../../service/useTVL";
import { formatNumber } from "../../utils/functions";
import { useTotalSupplyInUSD } from "../../service/amphorETH";

const sxVideo: SxProps<Theme> = (theme: Theme) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const SectionStart = () => {
  const { data: tvlLrtSummary, isLoading: isSlrtLoading } = useTVL("slrtVault", 1);
  const { data: tvlSummary, isLoading: isSmaLoading } = useTVL("smaVault", 1);
  const { data: supplyTVL, isLoading: isSupplyLoading } = useTotalSupplyInUSD();

  const isTVLLoading = isSmaLoading || isSlrtLoading;
  const isTotalTVLLoading = isSmaLoading || isSlrtLoading || isSupplyLoading;

  const supplyTVLUSDC = supplyTVL || 0;

  const slrtTVLUsdc = tvlLrtSummary?.slrtVault?.valueInUSDC || 0;
  const smaTVLUsdc = tvlSummary?.smaVault?.valueInUSDC || 0;

  const combinedTVL = smaTVLUsdc + slrtTVLUsdc + supplyTVLUSDC;
  const yieldOptimizerTVL = smaTVLUsdc + slrtTVLUsdc;

  const dateLaunchStatus = false;
  const dateLaunch = new Date(`Jul 14, 2022 00:00:00`);

  return (
    <Box className="container-section vh100">
      <CardMedia
        component="video"
        sx={sxVideo}
        src={VideoStart}
        autoPlay={true}
        controls={false}
        loop={true}
        muted={true}
        playsInline={true}
      />
      <Container className="container-in margin absolute bottom" sx={{ zIndex: 1, py: { xs: 2, sm: 6, lg: 8 } }}>
        <Stack
          direction={{ xs: "column", md: "column", lg: "row" }}
          justifyContent={{ xs: "center", md: "space-between" }}
          alignItems={{ xs: "center" }}
          gap={2}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent={{ xs: "center", md: "space-between" }}
            alignItems={{ xs: "center", md: "flex-end" }}
          >
            <Box textAlign={{ xs: "center", md: "left" }}>
              <Typography variant="h4">
                <Box component="span" color="primary.main">
                  Economic security
                </Box>{" "}
                provider
              </Typography>
              <Typography component="div" variant="h1" sx={{ fontSize: { xs: "3rem", sm: "4rem", md: "5rem" } }}>
                <Box component="span" sx={{ fontWeight: 300 }}>
                  Built to
                </Box>{" "}
                Last
              </Typography>
              <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ my: 1 }}>
                <ETHRestaking />
                <BTCRestaking />
                <OpenAPP text="Yield Optimizer vaults" />
              </Stack>
              <SocialLinks sx={{ mt: 1.5 }} />
            </Box>
            <Box>
              {dateLaunchStatus ? (
                <StartsCountdown targetDate={dateLaunch} sx={{ mt: 1.5, borderColor: "#2e3444" }} />
              ) : (
                ""
              )}
            </Box>
          </Stack>
          <Stack direction="column" justifyContent="center" gap={1}>
            <Stack direction={{ xs: "column", md: "row" }} justifyContent="center" gap={1}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                borderRadius="0.5rem"
                border="1px solid"
                sx={{
                  borderColor: (theme) => theme.palette.primary.main,
                  padding: "1rem 1.5rem",
                  border: "1px solid",
                  background: "linear-gradient(354.51deg, rgba(207, 124, 1, 0.11) 4.71%, rgba(0, 0, 0, 0.55) 96.01%)",
                  boxShadow: "0px 0.25rem 1.25rem 0px #CF7C0133",
                }}
              >
                <Typography variant="h5" textAlign="center">
                  Amphor LRTs deposited
                </Typography>
                {isSupplyLoading ? (
                  <CircularProgress size="24px" />
                ) : (
                  <Typography variant="h4">${formatNumber(supplyTVLUSDC, 0)}</Typography>
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                borderRadius="0.5rem"
                border="1px solid"
                sx={{
                  borderColor: (theme) => theme.palette.primary.main,
                  padding: "1rem 2rem",
                  border: "1px solid",
                  background: "linear-gradient(354.51deg, rgba(207, 124, 1, 0.11) 4.71%, rgba(0, 0, 0, 0.55) 96.01%)",
                  boxShadow: "0px 0.25rem 1.25rem 0px #CF7C0133",
                }}
              >
                <Typography variant="h5" textAlign="center">
                  Yield Optimizer Vaults
                </Typography>
                {isTVLLoading ? (
                  <CircularProgress size="24px" />
                ) : (
                  <Typography variant="h4">${formatNumber(yieldOptimizerTVL, 0)}</Typography>
                )}
              </Box>
            </Stack>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="0.5rem"
              border="1px solid"
              gap={0.5}
              sx={{
                borderColor: (theme) => theme.palette.primary.main,
                padding: "1rem 1rem",
                border: "1px solid",
                background: "linear-gradient(354.51deg, rgba(207, 124, 1, 0.11) 4.71%, rgba(0, 0, 0, 0.55) 96.01%)",
                boxShadow: "0px 0.25rem 1.25rem 0px #CF7C0133",
              }}
            >
              <Typography variant="h5">Combined TVL:</Typography>
              {isTotalTVLLoading ? (
                <CircularProgress size="24px" />
              ) : (
                <Typography variant="h4">${formatNumber(combinedTVL, 0)}</Typography>
              )}
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default SectionStart;
