import React from "react";
import { Link, CardMedia, SxProps, Theme } from "@mui/material";

interface Props {
  Title: string;
  Url: string;
  Img: string;
  ImgHover: string;
  Target?: string;
  sx?: SxProps<Theme>;
}

const sxComponent: SxProps<Theme> = (theme: Theme) => ({
  cursor: 'pointer',
  '& .default': {
    display: 'inline-block',
  },
  '& .hover': {
    display: 'none'
  },
  '&:hover .default': {
    display: 'none'
  },
  '&:hover .hover': {
    display: 'inline-block'
  }
});

const LogoLink = ({ Title, Url, Img, ImgHover, Target = '_blank', sx = [] }: Props) => {
  return (
    <Link title={Title} href={Url} target={Target} sx={sxComponent}>
      <CardMedia component="img" src={require('./../../' + Img)} className="default" alt={Title} title={Title} sx={sx} />
      <CardMedia component="img" src={require('./../../' + ImgHover)} className="hover" alt={Title} title={Title} sx={sx} />
    </Link>
  );
};

export default LogoLink;
