import { Box, Button, CardMedia, Container, Grid, Typography } from "@mui/material";
import TitleSection from "../../components/TitleSection";

import ImageAmphrETH from "./../../assets/images/amphrETH.svg";
import ImageAmphrBTC from "./../../assets/images/amphrBTC.svg";
import StakingAPR from "./../../assets/images/stakingAPR.svg";
import RestakingAPR from "./../../assets/images/restakingAPR.svg";
import SymbioticPoints from "./../../assets/images/symbioticPoints.svg";
import MellowPoints from "./../../assets/images/mellowPoints.svg";

import { AMPHORLRT_URL, AMPHORBTC_URL } from "../../constants/urls";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AnimationOnScroll } from "react-animation-on-scroll";

const RestakenToken = () => {
  return (
    <Box
      id="SectionRestakenToken"
      className="container-section margin minvh100"
      sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
    >
      <Container className="container-in margin" sx={{ py: 10 }}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap="16px">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut" offset={150}>
            <Box display="flex" gap={2}>
              <CardMedia
                component="img"
                src={ImageAmphrETH}
                alt="amphrETH"
                title="amphrETH"
                sx={{
                  display: "inline-block",
                  width: { xs: "60px", lg: "70px" },
                }}
              />
              <CardMedia
                component="img"
                src={ImageAmphrBTC}
                alt="amphrETH"
                title="amphrETH"
                sx={{
                  display: "inline-block",
                  width: { xs: "60px", lg: "70px" },
                }}
              />
            </Box>
          </AnimationOnScroll>
          <TitleSection Title="Liquid Restaked Token" sx={{ textTransform: "initial" }} />
          <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut" offset={150}>
            <Typography variant="body1" align="center">
              Liquid Restaked Token provides competitive, risk-adjusted yields through curated selection and active
              monitoring, mitigating operator authority bias, unintended slashing, and trust/security assumptions.
            </Typography>
            <Typography variant="h4" align="center">
              Stakers are earning:
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent={{ md: "space-between", sm: "center" }}
              spacing={1}
              sx={{ mt: 1 }}
            >
              <Grid item xs={6} sm={4} md={2} textAlign="center">
                <CardMedia
                  component="img"
                  src={StakingAPR}
                  alt="StakingAPR"
                  title="StakingAPR"
                  sx={{
                    display: "inline-block",
                    width: "40px",
                    mb: 1,
                  }}
                />
                <Typography variant="h5">Staking APR</Typography>
                <Typography component="div">(3.2% Net APY)</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign="center">
                <CardMedia
                  component="img"
                  src={RestakingAPR}
                  alt="RestakingAPR"
                  title="RestakingAPR"
                  sx={{
                    display: "inline-block",
                    width: "40px",
                    mb: 1,
                  }}
                />
                <Typography variant="h5">Restaking APR</Typography>
                <Typography component="div">(soon)</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign="center">
                <CardMedia
                  component="img"
                  src={SymbioticPoints}
                  alt="SymbioticPoints"
                  title="SymbioticPoints"
                  sx={{
                    display: "inline-block",
                    width: "40px",
                    mb: 1,
                  }}
                />
                <Typography variant="h5">Symbiotic Points</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign="center">
                <CardMedia
                  component="img"
                  src={MellowPoints}
                  alt="MellowPoints"
                  title="MellowPoints"
                  sx={{
                    display: "inline-block",
                    width: "40px",
                    mb: 1,
                  }}
                />
                <Typography variant="h5">Mellow Points</Typography>
              </Grid>
            </Grid>
            <Box
              display="flex"
              justifyContent="center"
              sx={{ width: "100%", mt: 2 }}
              flexDirection={{ xs: "column", sm: "row" }}
              gap={2}
            >
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                href={AMPHORLRT_URL}
                target="_blank"
              >
                Deposit ETH
              </Button>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                href={AMPHORBTC_URL}
                target="_blank"
              >
                Deposit BTC
              </Button>
            </Box>
          </AnimationOnScroll>
        </Box>
      </Container>
    </Box>
  );
};

export default RestakenToken;
