import { ethers } from "ethers";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const provider = new ethers.providers.JsonRpcProvider("https://rpc.mevblocker.io", "mainnet");
const ethContractAddress = "0x5fd13359ba15a84b76f7f87568309040176167cd";
const btcContractAddress = "0x64047dD3288276d70A4F8B5Df54668c8403f877F";
const abi = [
  // ERC20 ABI
  "function totalSupply() view returns (uint256)",
];

const ethContract = new ethers.Contract(ethContractAddress, abi, provider);
const btcContract = new ethers.Contract(btcContractAddress, abi, provider);

const getTotalSupply = async (contract: ethers.Contract): Promise<string> => {
  const totalSupply = await contract.totalSupply();
  return ethers.utils.formatUnits(totalSupply, 18);
};

const getPrice = async (): Promise<{ btc: number; eth: number }> => {
  const response = await axios.get(
    "https://api.coingecko.com/api/v3/simple/price?ids=wrapped-steth,wrapped-bitcoin&vs_currencies=usd"
  );
  return { eth: response.data["wrapped-steth"].usd, btc: response.data["wrapped-bitcoin"].usd };
};

const getTotalSupplyInUSD = async (): Promise<number> => {
  const totalSupplyETH = await getTotalSupply(ethContract);
  const totalSupplyBTC = await getTotalSupply(btcContract);

  const { eth: wstETHPrice, btc: wBTCPrice } = await getPrice();

  const totalSupplyETHInUSD = parseFloat(totalSupplyETH) * wstETHPrice;
  const totalSupplyBTCInUSD = parseFloat(totalSupplyBTC) * wBTCPrice;

  return totalSupplyETHInUSD + totalSupplyBTCInUSD;
};

export const useTotalSupplyInUSD = () => {
  return useQuery({
    queryKey: ["getTotalSupplyInUSD"],
    queryFn: () => getTotalSupplyInUSD(),
    gcTime: Infinity,
  });
};
