import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Button,
  Divider,
  Stack,
  SxProps,
  Theme,
  Typography
} from "@mui/material";
import { ADDITIONAL_URL } from "../../constants/urls";

interface Props {
  targetDate: Date;
  sx?: SxProps<Theme>;
}

interface TimeDisplayValuesType {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface CounterType {
  displayValue: number;
  label: string;
}

// timing
let targetDateTime: number = 0;
const generateTimeDisplay = (targetDateTime: number): TimeDisplayValuesType => {
  const rightJustNow = new Date().getTime();
  const runway = targetDateTime - rightJustNow;
  const stateObj = {
    days: Math.floor(runway / (1000 * 60 * 60 * 24)),
    hours: Math.floor((runway % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((runway % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((runway % (1000 * 60)) / 1000)
  };
  return stateObj;
};

// components
const Counter = ({ displayValue, label }: CounterType) => (
  <Typography
    component="div"
    variant="body1"
    color="secondary"
    textAlign="center"
    sx={{ px: 1 }}
  >
    <Typography
      component="span"
      sx={{ display: "inline-block", color: "#fff", fontWeight: "700" }}
    >
      {displayValue}
    </Typography>{" "}
    {label}
  </Typography>
);

const StartsCountdown = ({ targetDate = new Date(), sx = [] }: Props) => {
  targetDateTime = targetDate.getTime();

  const [timeDisplay, setTimeDisplay] = React.useState<TimeDisplayValuesType>(
    generateTimeDisplay(targetDateTime)
  );

  React.useEffect(() => {
    setInterval(
      () => setTimeDisplay(generateTimeDisplay(targetDateTime)),
      1000
    );
  }, []);

  return (
    <Box component="fieldset" sx={sx}>
      <legend>
        <Typography
          component="div"
          color="primary"
          sx={{ fontWeight: 700, px: 0.5 }}
        >
          LBP Starts
        </Typography>
      </legend>
      {targetDate > new Date() ? (
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          justifyContent="center"
          sx={{ mb: 1 }}
        >
          <Counter displayValue={timeDisplay.days} label={"D"} />
          <Counter displayValue={timeDisplay.hours} label={"H"} />
          <Counter displayValue={timeDisplay.minutes} label={"M"} />
          <Counter displayValue={timeDisplay.seconds} label={"S"} />
        </Stack>
      ) : (
        <Typography
          variant="body1"
          sx={{ fontWeight: 700, my: 0.5 }}
          align="center"
        >
          Coming soon
        </Typography>
      )}
      <Button
        variant="outlined"
        color="secondary"
        endIcon={<ArrowForwardIcon />}
        sx={{ color: "secondary.main" }}
        href={ADDITIONAL_URL}
        target="_blank"
      >
        Participate in iAMPR auction
      </Button>
    </Box>
  );
};

export default StartsCountdown;
