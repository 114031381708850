import { Box, CircularProgress, SvgIcon } from "@mui/material";
import { ReactComponent as LogoIcon } from "./../../assets/images/logo-icon-white.svg";

const Loading = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh" }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{ position: "relative", width: "130px", height: "130px" }}
      >
        <CircularProgress size={130} thickness={2} color="secondary" />
        <SvgIcon
          component={LogoIcon}
          sx={{ width: "85px", height: "85px" }}
          className="loader-icon"
          inheritViewBox
        />
      </Box>
    </Box>
  );
};

export default Loading;
