import React from "react";
import { Box, Container, SxProps, Theme, Typography } from "@mui/material";
import backgroundSection from "./../../assets/images/background-vision.jpg";
import backgroundSectionMobile from "./../../assets/images/background-vision-mobile.jpg";
import {AnimationOnScroll} from "react-animation-on-scroll";
import TitleSection from "../../components/TitleSection";

const sxSectionBackground: SxProps<Theme> = (theme: Theme) => ({
  background: `transparent url(${backgroundSection}) center center no-repeat`,
  [theme.breakpoints.down("sm")]: {
    background: `transparent url(${backgroundSectionMobile}) center center no-repeat`
  },
  backgroundAttachment: "fixed",
  backgroundSize: "cover"
});

const SectionVision = () => {
  return (
    <Box
      id="SectionVision"
      className="container-section margin minvh100"
      sx={sxSectionBackground}
    >
      <Container className="container-in margin" sx={{ py: 10, display: 'flex', alignItems: 'center' }}>
        <Box>
          <TitleSection
            Title={
              <>
                Optimizing Yield Farming in
                <br />the new era of{" "}
                <Typography variant="caption" color="primary">
                  Restaking
                </Typography>
              </>
            }
          />
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            animateOut="animate__fadeOut"
            offset={150}
          >
            <Typography variant="subtitle1" align="center" sx={{ mb: 1.5 }}>
              Amphor protocol sets the standard for Yield automation.
            </Typography>
            <Typography variant="body1" align="center" sx={{ mb: 1.5 }}>
              Powered by tier1 protocols and assets:{" "}
              <Typography variant="caption">Amphor Vaults</Typography> provide a
              new approach of keeping exposure to the most advantageous DeFi
              opportunities for both{" "}
              <Typography variant="caption">Stablecoins</Typography> and{" "}
              <Typography variant="caption">ETH</Typography>, in a highly liquid
              and cost-efficient way.
            </Typography>
          </AnimationOnScroll>
        </Box>
      </Container>
    </Box>
  );
};

export default SectionVision;
