import React from "react";
import { Box, CardMedia, Container, Grid, SxProps, Theme, Typography } from "@mui/material";
import backgroundSection from "./../../assets/images/background-metrics.jpg";
import backgroundSectionMobile from "./../../assets/images/background-metrics-mobile.jpg";
import TitleSection from "../../components/TitleSection";

import ImageTVL from "./../../assets/images/tvl-title.png";
//import ImageAPR from './../../assets/images/apr-title.png';
import ImageAPY from "./../../assets/images/APY.png";
import ImageV1 from "./../../assets/images/v1-title.png";
import NumberCountUp from "../../components/NumberCountUp";

import { IoMdTrophy } from "react-icons/io";
import { useTVL } from "../../service/useTVL";
import { formatNumber } from "../../utils/functions";
import { useVaultPerf } from "../../service/performance";
import { useTotalSupplyInUSD } from "../../service/amphorETH";

const sxSectionBackground: SxProps<Theme> = (theme: Theme) => ({
  background: `#070510 url(${backgroundSection}) center 1rem no-repeat`,
  [theme.breakpoints.down("sm")]: {
    background: `#070510 url(${backgroundSectionMobile}) center 1rem no-repeat`,
  },
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
});

const SectionMetrics = () => {
  const { data: tvlSummary } = useTVL("smaVault", 1);
  const { data: tvlLrtSummary } = useTVL("slrtVault", 1);
  const { data: performanceSMA } = useVaultPerf("smaVault", 1);
  const { data: performanceSLRT } = useVaultPerf("slrtVault", 1);
  const { data: supplyTVL } = useTotalSupplyInUSD();
  const smaTVLUsdc = tvlSummary?.smaVault?.valueInUSDC || 0;
  const slrtTVLUsdc = tvlLrtSummary?.slrtVault?.valueInUSDC || 0;
  const supplyTVLUSDC = supplyTVL || 0;
  const metricV1 = (performanceSLRT?.ampr || 0) + (performanceSMA?.ampr || 0);
  const metricAPR =
    performanceSMA
      ?.epoch_breakdown!.sort((a, b) => a.epoch_start - b.epoch_start)
      .filter(({ current }) => !current)
      .at(-1)?.cumulated_avg_apy || 0;

  return (
    <Box id="SectionMetrics" className="container-section margin minvh100" sx={sxSectionBackground}>
      <Container className="container-in margin" sx={{ py: 10 }}>
        <TitleSection Title="Metrics" />
        <Grid
          container
          direction="row"
          justifyContent={{ md: "space-between", sm: "center" }}
          alignItems="center"
          spacing={3}
          sx={{ mt: "7rem" }}
        >
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <CardMedia
              component="img"
              src={ImageTVL}
              alt="TVL"
              title="TVL"
              sx={{
                display: "inline-block",
                width: { xs: "100%", lg: "initial" },
              }}
            />
            <Typography component="div" variant="h1" sx={{ mt: "-6rem" }}>
              $ {formatNumber(slrtTVLUsdc + smaTVLUsdc + supplyTVLUSDC, 0)}
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              Deployed on auto-rebalancing
              <br />
              ERC-4626 Vault
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <CardMedia
              component="img"
              src={ImageAPY}
              alt="APR"
              title="APR"
              sx={{
                display: "inline-block",
                width: { xs: "100%", lg: "initial" },
              }}
            />
            <NumberCountUp Number={metricAPR} Format={["%"]} SubText="PAID IN LRTs" Duration={2} sx={{ mt: "-6rem" }} />
            <Typography component="div" variant="body1" sx={{ mt: 2 }}>
              <Typography component="div" variant="h5" sx={{ mb: 1 }}>
                <Typography
                  component="div"
                  sx={{
                    color: "primary.main",
                    fontSize: "1.8rem",
                  }}
                >
                  <IoMdTrophy />
                </Typography>
                Additional Earnings
              </Typography>
              Symbiotic Points, Karak Points, EigenLayer Points, Mellow Points, Zircuit points, Swell Pearls, LRT Points
              +{" "}
              <Typography component="span" sx={{ color: "primary.main", fontWeight: 700 }}>
                Amphor points
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <CardMedia
              component="img"
              src={ImageV1}
              alt="V1"
              title="V1"
              sx={{
                display: "inline-block",
                width: { xs: "100%", lg: "initial" },
              }}
            />
            <NumberCountUp Number={metricV1} Format={[""]} SubText="AMPR POINTS" Duration={3} sx={{ mt: "-6rem" }} />
            <Typography variant="body1" sx={{ mt: 2 }}>
              To be distributed to:
              <br />
              2618 users
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SectionMetrics;
