import React from "react";
import { Box, CardMedia, Grid, SxProps, Theme, Typography } from "@mui/material";
import ImageTitle from "./../../assets/images/LRTfocused-title.png";
import ImageIllustration from "./../../assets/images/LRTfocused-image.png";
import { AnimationOnScroll } from "react-animation-on-scroll";

interface Props {
  sx?: SxProps<Theme>;
  className?: string;
}

const sxContent: SxProps<Theme> = (theme: Theme) => ({
  position: "relative",
  marginTop: "-6rem",
  [theme.breakpoints.down("md")]: {
    marginTop: "-2rem",
  },
});

const SectionLRTFocused = ({ sx = [], className = "" }: Props) => {
  return (
    <Box id="SectionWhyLRTFocused" sx={sx} className={className}>
      <Box sx={{ textAlign: "center" }}>
        <AnimationOnScroll animateIn="animate__fadeInDownBig" animateOut="animate__fadeOutUpBig">
          <CardMedia component="img" src={ImageTitle} alt="LRT-Focused" sx={{ display: "inline-block" }} />
        </AnimationOnScroll>
      </Box>
      <Grid container alignItems="center" sx={sxContent}>
        <Grid item xs={12} md={6} textAlign={{ xs: "center", md: "initial" }}>
          <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOut="animate__slideOutLeftBig">
            <CardMedia
              component="img"
              src={ImageIllustration}
              alt="LRT-Focused Icon"
              sx={{
                display: "inline-block",
                width: { xs: "50%", md: "initial" },
              }}
            />
          </AnimationOnScroll>
        </Grid>
        <Grid item xs={12} md={6} textAlign={{ xs: "center", md: "initial" }}>
          <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut">
            <Box>
              <Typography variant="h2" sx={{ mb: 2, mt: { xs: 2, md: 0 } }}>
                LRT-Focused
              </Typography>
              <Box>
                <Typography variant="body1" component="p">
                  <Typography variant="caption">Amphor LRT Omni-vault </Typography>
                  is a diversified basket of symbiotic restaked assets designed to maximize earnings by generating yield
                  and accumulating points.
                </Typography>
                <Typography variant="body1" component="p">
                  Our proprietary <Typography variant="caption">AmphrETH LRT </Typography>
                  offers competitive, risk-adjusted yields for ETH through curated selection and active monitoring,
                  making us one of the largest restakers on Symbiotic and ensuring robust security and optimal returns.
                </Typography>
              </Box>
            </Box>
          </AnimationOnScroll>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SectionLRTFocused;
