import { createTheme } from "@mui/material";
import { typography } from "./typography";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    compact: true;
  }
}

export const createMuiTheme = () => {
  return createTheme({
    spacing: 16,
    palette: {
      primary: {
        main: "#d37e02"
      },
      secondary: {
        main: "#454545"
      },
      text: {
        primary: "#d37e02",
        secondary: "#9592ad"
      },
      background: {
        paper: "#06050F",
      },
      divider: "rgba(69,69,69,1)"
    },
    shape: {
      borderRadius: 5
    },
    typography,
    components: {
      MuiCssBaseline: {},
      MuiLink: {
        styleOverrides: {
          root: {
            color: "#fff",
            "&:hover": {
              color: "#d37e02"
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: "#fff",
            fontWeight: 700,
            padding: "0.5rem 1.5rem 0.5rem 1.5rem"
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: "#6c6c6b",
            border: "1px solid #454545",
            borderRadius: 10,
            padding: "0.5rem 2rem 0.5rem 2rem"
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            ".MuiInputBase-root": {
              border: "none !important",
              padding: "0 0 0 0"
            },
            ".MuiInputBase-root input, .MuiInputBase-root textarea": {
              color: "#6c6c6b",
              border: "1px solid #454545",
              borderRadius: 10,
              padding: "1rem 2rem 1rem 2rem"
            },
            ".MuiInputBase-root fieldset": {
              border: "none !important"
            }
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            ".MuiDialogContentText-root": {
              color: "white",
              maxHeight: "calc(100vh - 10vh)"
            },
            ".MuiDialogContentText-root > p": {
              paddingBottom: "1rem"
            },
            ".MuiDialog-paper": {
              border: "1px solid rgba(255,255,9,0.3)"
            }
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxSizing: "border-box"
          }
        }
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    }
  });
};
