import React from 'react';
import {
  Box,
  CardMedia,
  Grid,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import ImageTitle from './../../assets/images/resilient-title.png';
import ImageIllustration from './../../assets/images/resilient-image.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';

interface Props {
  sx?: SxProps<Theme>;
  className?: string;
}

const sxContent: SxProps<Theme> = (theme: Theme) => ({
  position: 'relative',
  marginTop: '-6rem',
  [theme.breakpoints.down('md')]: {
    marginTop: '-2rem',
  },
});

const SectionResilient = ({ sx = [], className = '' }: Props) => {
  return (
    <Box id="SectionWhyResilient" sx={sx} className={className}>
      <Box sx={{ textAlign: 'center' }}>
        <AnimationOnScroll
          animateIn="animate__fadeInDownBig"
          animateOut="animate__fadeOutUpBig"
        >
          <CardMedia
            component="img"
            src={ImageTitle}
            alt="Resilient"
            sx={{ display: 'inline-block' }}
          />
        </AnimationOnScroll>
      </Box>
      <Grid container alignItems="center" sx={sxContent}>
        <Grid item xs={12} md={6} textAlign={{ xs: 'center', md: 'initial' }}>
          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            animateOut="animate__slideOutLeftBig"
          >
            <CardMedia
              component="img"
              src={ImageIllustration}
              alt="Resilient Icon"
              sx={{
                display: 'inline-block',
                width: { xs: '50%', md: 'initial' },
              }}
            />
          </AnimationOnScroll>
        </Grid>
        <Grid item xs={12} md={6} textAlign={{ xs: 'center', md: 'initial' }}>
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            animateOut="animate__fadeOut"
          >
            <Box>
              <Typography variant="h2" sx={{ mb: 2, mt: { xs: 2, md: 0 } }}>
                Resilient
              </Typography>
              <Box>
                <Typography variant="body1" component="p">
                  The high composability of ERC-4626 Vault structure and ERC-20 compatibility
                  ensures that Amphor architecture maintains resilience to
                  shifting market trends.
                </Typography>
              </Box>
            </Box>
          </AnimationOnScroll>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SectionResilient;
