export const datas_faq = [
  {
    ID: 'faq1',
    Question: 'What is Amphor?',
    Answer:
      'Amphor protocol is a DeFi application deployed on Ethereum blockchain since October 2023, leveraging the ERC-4626 composability across a set of tier 1 protocols and yield-bearing assets. Amphor provides DeFi yield strategy exposure to web3 users via “set and forget” approach.\nThe Protocol is governed by Amphor DAO.',
    Links: '',
  },
  {
    ID: 'faq2',
    Question: 'Is Amphor audited and safe to use?',
    Answer:
      'Amphor v1 smart contracts have been audited by Bailsec, Salus and Sherlock. Audit reports are available $here. No severe vulnerabilities have been detected.',
    Links:
      'https://github.com/AmphorProtocol/asynchronous-vault/tree/main/audits',
  },
  {
    ID: 'faq3',
    Question: 'What can I do on Amphor?',
    Answer:
      'Web3 users can interact with vaults until beta test ends, discover v1 $vaults (LRT-focus vault), engage in the $Quest&emsp13;Campaign  to earn Sherds, or participate in the upcoming Amphor auction (LBP) expected in Q2-2024.',
    Links: 'https://app.amphor.io/earn https://quests.amphor.io/en/dashboard',
  },
  {
    ID: 'faq4',
    Question: 'How to deposit into a vault?',
    Answer:
      'Users can connect to the application using on-chain compatible wallets from the Amphor web page and interact with a “zapper” or “deposit” modules from the deposit $page. By depositing or “zapping in”, users instantly get exposure to underlying yield strategies and start generating returns.\nAll user interactions are trackable on the dashboard or directly on-chain via Etherscan.\nPlease $contact the community if you encounter any issues with Amphor UI or functionality.',
    Links: 'https://app.amphor.io/earn https://t.me/amphor_official',
  },
  {
    ID: 'faq5',
    Question: 'My wallet is connected but I can’t deposit?',
    Answer:
      'By default, deposits and withdrawals are only available at the end of each epoch. Users can nevertheless pre-set deposits and withdrawals to be automatically executed as soon as these functions become available following vault settlement.',
    Links: '',
  },
  {
    ID: 'faq6',
    Question:
      'Do I still accrue my LRT and Eigen points when depositing in Amphor vault?',
    Answer:
      'Yes, depositing LRTs into a vault compounds all rewards associated with the underlying assets and protocols, including points and all other non-liquid incentives. Please refer to the $Gitbook for additional information about vault incentive accrual mechanism.',
    Links: 'https://defivaults.gitbook.io/amphor/',
  },
  {
    ID: 'faq7',
    Question: 'Where can I find Amphor on-chain data?',
    Answer:
      'Amphor smart contracts list can be found on the Amphor $Gitbook SC section.\nAmphor protocol KPIs can tracked on $Defillama.',
    Links:
      'https://defivaults.gitbook.io/amphor/technical/v1 https://defillama.com/protocol/amphor',
  },
  {
    ID: 'faq8',
    Question: 'What are the risks?',
    Answer:
      'Before interacting with Amphor, all users must conduct their own research from the available documentation ( $Gitbook, $Github) and $blockchain data.\nAll users interacting with Amphor, including the website, the DAO, and smart contracts, bear full responsibility for their actions, and acknowledge all the risks related to interacting with DeFi applications, including partial or total loss of deposited assets.\nBy interacting with Amphor, all users, without any exception, unilaterally agree with Amphor protocol $Terms&emsp13;and&emsp13;conditions and therefore forfeits the rights to any liability claims against the protocol, development team or the DAO.\nFor any information regarding the risks specific to the vault, please refer to the Gitbook vault section.',
    Links:
      'https://defivaults.gitbook.io/amphor https://github.com/orgs/AmphorProtocol/repositories https://etherscan.io/ https://amphorprotocol.github.io/terms-of-service/',
  },
  {
    ID: 'faq9',
    Question: 'Additional questions?',
    Answer: 'Reach out to the community on $Telegram + $Twitter.',
    Links: 'https://t.me/amphor_official https://twitter.com/Amphor_io',
  },
];

