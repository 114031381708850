import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Toolbar from "@mui/material/Toolbar";
import Logo from "./../../assets/images/logo.svg";
import IconGitbook from "./../../assets/images/gitbook.svg";
import { Container, Link, Stack, styled, useScrollTrigger } from "@mui/material";
import { ScrollToElement } from "../../lib/utils";
import ConnectWallet from "../ConnectWallet";
import { GITBOOK_URL } from "../../constants/urls";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const sxContainer = {
  /*backgroundColor: "rgba(6,5,15,0.6)",*/
  padding: "1rem 0 1rem 0 !important",
  boxShadow: "none",
};

const sxContainerMobile = {
  textAlign: "left",
};

const sxLinkMobile = {
  display: "block",
  padding: "1rem",
};

const NavDesktop = styled(Stack)(({ theme }) => ({
  width: "100%",
  alignItems: "center",
}));

const StyledDocs = styled(Link)(({ theme }) => ({
  color: "#fff",
  fontWeight: 700,
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    color: "#fff",
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: "none",
  transition: "all 0.2s",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.text.primary,
  },
}));

const ScrollHandler = (props: any) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 150,
    target: props.window ? props.window : undefined,
  });

  return React.cloneElement(props.children, {
    style: {
      backgroundColor: trigger ? "rgba(6,5,15,0.6)" : "transparent",
    },
  });
};

export default function HeaderAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const goToSection = (elementId: string) => {
    ScrollToElement(elementId);
    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  const renderLogo = () => {
    return (
      <StyledLink
        onClick={() => {
          goToSection("SectionTop");
        }}
      >
        <img src={Logo} alt="Amphor" />
      </StyledLink>
    );
  };

  const renderDocs = () => {
    return (
      <StyledDocs href={GITBOOK_URL} target="_blank">
        <Stack direction="row" alignItems="center" justifyContent="center">
          <img src={IconGitbook} alt="Docs" />
          <Box sx={{ marginLeft: "0.5rem" }}>Docs</Box>
        </Stack>
      </StyledDocs>
    );
  };

  const renderLinkScroll = (label: string, target: string, mobile?: boolean) => {
    return (
      <StyledLink
        onClick={() => {
          goToSection(target);
        }}
        sx={mobile ? sxLinkMobile : {}}
      >
        {label}
      </StyledLink>
    );
  };

  const drawer = (
    <Box sx={sxContainerMobile} onClick={handleDrawerToggle}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ my: 1, mx: 1, position: "relative" }}
      >
        {renderLogo()}
        <Box>
          <ConnectWallet sx={{ px: 1 }} />
          <IconButton
            aria-label="Close Menu"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" }, color: "#fff" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Stack>
      <Divider />
      <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
        {renderLinkScroll("Vision", "SectionVision", true)}
        {renderLinkScroll("Service", "SectionService", true)}
        {renderLinkScroll("LRT", "SectionRestakenToken", true)}
        {renderLinkScroll("Why Amphor?", "SectionWhy", true)}
        {renderLinkScroll("Metrics", "SectionMetrics", true)}
        {renderLinkScroll("Associated with", "SectionAssociation", true)}
        {renderLinkScroll("FAQ", "SectionFAQ", true)}
        {renderDocs()}
      </Box>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box id="SectionTop" sx={{ display: "flex" }}>
      <ScrollHandler>
        <AppBar sx={sxContainer} component="nav" position="fixed">
          <Toolbar
            sx={{
              padding: "0 1rem 0 1rem !important",
              justifyContent: { xs: "space-between", md: "center" },
            }}
          >
            <Box sx={{ display: { md: "none" } }}>{renderLogo()}</Box>
            <IconButton
              aria-label="Open Menu"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { md: "none" }, color: "#fff" }}
            >
              <MenuIcon />
            </IconButton>
            <Container
              sx={{
                padding: "0 1rem 0 1rem !important",
                display: { xs: "none", md: "block" },
              }}
            >
              <NavDesktop direction="row" justifyContent="space-between">
                {renderLogo()}
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={{ xl: 2, xs: 1 }}>
                  {renderLinkScroll("Vision", "SectionVision")}
                  {renderLinkScroll("Service", "SectionService")}
                  {renderLinkScroll("LRT", "SectionRestakenToken")}
                  {renderLinkScroll("Why Amphor?", "SectionWhy")}
                  {renderLinkScroll("Metrics", "SectionMetrics")}
                  {renderLinkScroll("Associated with", "SectionAssociation")}
                  {renderLinkScroll("FAQ", "SectionFAQ")}
                  {renderDocs()}
                  <ConnectWallet sx={{ px: 1 }} />
                </Stack>
              </NavDesktop>
            </Container>
            <Box
              sx={{
                display: { xs: "block", md: "none" },
                position: "absolute",
                top: 5,
                right: { xs: 50, md: 100 },
              }}
            >
              <ConnectWallet sx={{ px: 1 }} />
            </Box>
          </Toolbar>
        </AppBar>
      </ScrollHandler>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: "100%" },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
