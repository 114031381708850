import React from "react";
import { Button, Typography } from "@mui/material";
import { AMPHORBTC_URL } from "../../constants/urls";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const BTCRestaking = () => {
  return (
    <Button
      variant="outlined"
      color="primary"
      href={AMPHORBTC_URL}
      endIcon={<ArrowForwardIcon color="primary" />}
      target="_blank"
    >
      <Typography fontWeight="700" variant="button" align="center">
        Symbiotic BTC Restaking
      </Typography>
    </Button>
  );
};

export default BTCRestaking;
