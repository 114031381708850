import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Typography
} from "@mui/material";
import React from "react";
import { FaChevronDown } from "react-icons/fa";
import parse from 'html-react-parser';

interface ItemFAQ {
  ID: string;
  Question: string;
  Answer: string;
  Links: string;
}

interface Props {
  Datas: ItemFAQ[];
}

const Items = ({ Datas }: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : "");
    };

  const renderItem = (
    ID: string,
    Question: string,
    Answer: string,
    Links: string
  ) => {
    return (
      <Accordion
        key={ID}
        expanded={expanded === ID}
        onChange={handleChange(ID)}
        sx={{
          borderColor: expanded === ID ? "primary.main" : "text.secondary",
          "& .MuiAccordionSummary-root, & .MuiAccordionSummary-expandIconWrapper":
            {
              color: expanded === ID ? "primary.main" : "text.secondary"
            }
        }}
      >
        <AccordionSummary
          expandIcon={<FaChevronDown />}
          aria-controls={ID + "-content"}
          id={ID + "-header"}
          sx={{ py: 1, px: 0 }}
        >
          <Typography component="div">{Question}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0 }}>
          <Typography variant="body1" className="text-FAQ">
            {renderAnswerWithLink(Answer, Links)}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderAnswerWithLink = (answer: string, link: string) => {
    const links = link.split(" ");
    let dollarWordCount = 0;
    const answerParts = answer.split(" ").map((part) => {
      if (part.startsWith("$")) {
        const cleanPart = part.substring(1);
        const partLink = links[dollarWordCount] || "";
        dollarWordCount++;
        if (partLink) {
          return (
            <Link
              href={partLink}
              key={dollarWordCount}
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontWeight: 700, textDecoration: "none" }}
            >
              {parse(cleanPart) + " "}
            </Link>
          );
        }
        return cleanPart + " ";
      }
      return part + " ";
    });
    return <>{answerParts}</>;
  };

  return (
    <>
      {Datas.map((item: ItemFAQ, i) =>
        renderItem(item.ID, item.Question, item.Answer, item.Links)
      )}
    </>
  );
};

export default Items;
