import { useQuery } from "@tanstack/react-query";
import { AmphorTVL } from "../types/TVL";

export const fetchTVL = async (
    vaultSelected?: string,
    networkId?: number
): Promise<AmphorTVL> => {
    const response = await fetch(
        `https://app.amphor.io/api/tvl?vault=${vaultSelected}&networkId=${networkId}`
    );
    if (!response.ok) throw await response.json();

    return response.json();
};

export const useTVL = (vaultSelected?: string, networkId?: number) => {
    return useQuery({
        queryKey: ["tvl", vaultSelected, networkId],
        queryFn: () => fetchTVL(vaultSelected, networkId),
        gcTime: Infinity,
    });
};
