import React from "react";
import { Box, Stack, CardMedia, Container, SxProps, Theme, Typography } from "@mui/material";
import Logo from "./../../assets/images/logo-footer.svg";
import VideoBackground from "./../../assets/videos/video-particles-default.mp4";
import SocialLinks from "../../components/SocialLinks";

const sxVideoBackground: SxProps<Theme> = (theme: Theme) => ({
  objectFit: "cover"
});

const Footer = () => {
  return (
    <Box id="Footer" className="container-section">
      <CardMedia
        component="video"
        sx={sxVideoBackground}
        src={VideoBackground}
        autoPlay={true}
        controls={false}
        loop={true}
        muted={true}
        playsInline={true}
      />
      <Container
        className="container-in margin absolute inheritH"
        sx={{ zIndex: 1, py: 10 }}
      >
        <Stack direction="column" justifyContent="center" alignItems="center" sx={{ mb: 6 }}>
          <Box className="divider-footer" sx={{ mb: 3 }}></Box>
          <CardMedia component="img" src={Logo} sx={{ width: "133px", display: "inline-block" }} />
          <SocialLinks sx={{ my: 1.5 }} />
          <Typography component="div" variant="body2" sx={{ color: "#5C5887" }}>&copy; {new Date().getFullYear()} Amphor</Typography>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
