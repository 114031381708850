import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, SxProps, Theme } from "@mui/material";
import { AMPHORAPP_URL } from "../../constants/urls";

interface Props {
  sx?: SxProps<Theme>;
}

const ConnectWallet = ({ sx = [] }: Props) => {
  return (
    <Button
      variant="contained"
      color="primary"
      endIcon={<ArrowForwardIcon />}
      href={AMPHORAPP_URL}
      target="_blank"
      sx={sx}
    >
      Launch APP
    </Button>
  );
};

export default ConnectWallet;
