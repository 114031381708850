import React from "react";
import {
  SxProps,
  Theme,
  Typography
} from "@mui/material";
import {AnimationOnScroll} from "react-animation-on-scroll";

interface Props {
  Title: JSX.Element | string;
  sx?: SxProps<Theme>;
}

const TitleSection = ({ Title, sx = [] }: Props) => {
  return (
    <AnimationOnScroll
          animateIn="animate__bounceIn"
          animateOut="animate__bounceOut"
          offset={150}
        >
          <Typography variant="h1" align="center" sx={{ mb: 1.5, ...sx }}>
            {Title}
          </Typography>
        </AnimationOnScroll>
  );
};

export default TitleSection;
