import React from "react";
import { Button, Typography } from "@mui/material";
import { AMPHORLRT_URL } from "../../constants/urls";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ETHRestaking = () => {
  return (
    <Button
      variant="outlined"
      color="primary"
      href={AMPHORLRT_URL}
      endIcon={<ArrowForwardIcon color="primary" />}
      target="_blank"
    >
      <Typography fontWeight="700" variant="button" align="center">
        Symbiotic ETH Restaking
      </Typography>
    </Button>
  );
};

export default ETHRestaking;
