import React from "react";
import {
  Box,
  CardMedia,
  Grid,
  SxProps,
  Theme,
  Typography
} from "@mui/material";
import ImageTitle from "./../../assets/images/adaptive-title.png";
import ImageIllustration from "./../../assets/images/adaptive-image.png";
import { AnimationOnScroll } from "react-animation-on-scroll";

interface Props {
  sx?: SxProps<Theme>;
  className?: string;
}

const sxContent: SxProps<Theme> = (theme: Theme) => ({
  position: "relative",
  marginTop: "-8rem",
  [theme.breakpoints.down("md")]: {
    marginTop: "-4rem"
  }
});

const SectionAdaptive = ({ sx = [], className = "" }: Props) => {
  return (
    <Box id="SectionWhyAdaptive" sx={sx} className={className}>
      <Box sx={{ textAlign: "center" }}>
        <AnimationOnScroll
          animateIn="animate__fadeInDownBig"
          animateOut="animate__fadeOutUpBig"
        >
          <CardMedia
            component="img"
            src={ImageTitle}
            alt="Adaptive"
            sx={{ display: "inline-block" }}
          />
        </AnimationOnScroll>
      </Box>
      <Grid container alignItems="center" sx={sxContent}>
        <Grid item xs={12} md={6} textAlign={{ xs: "center", md: "initial" }}>
          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            animateOut="animate__slideOutLeftBig"
          >
            <CardMedia
              component="img"
              src={ImageIllustration}
              alt="Adaptive Icon"
              sx={{
                display: "inline-block",
                width: { xs: "50%", md: "initial" }
              }}
            />
          </AnimationOnScroll>
        </Grid>
        <Grid item xs={12} md={6} textAlign={{ xs: "center", md: "initial" }}>
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            animateOut="animate__fadeOut"
          >
            <Box>
              <Typography variant="h2" sx={{ mb: 2, mt: { xs: 2, md: 0 } }}>
                Adaptive
              </Typography>
              <Box>
                <Typography variant="body1" component="p">
                  Amphor Omni-vaults dynamically allocate assets to the most competitive risk-adjusted yield returns in DeFi. Amphor vaults enables gas cost mutualization and autocompounds in a highly liquid environment to maximize these returns.
                </Typography>
              </Box>
            </Box>
          </AnimationOnScroll>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SectionAdaptive;
